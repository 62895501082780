import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';


import bllogo from '../bidlive-logo.jpg';
import bllogosmlr from '../bidlive-logo-smlr.jpg';
import {FormControl, Button} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { faUser } from '@fortawesome/free-solid-svg-icons'


import {categsmryUpdat} from '../actions';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'



//import {Modal, Form} from 'react-bootstrap'
//import UserRegModal from './UserRegModal.js';



import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'
import ThankYouModal from './ThankYouModal.js'


function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)===' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function deleteCookie() {

   document.cookie = "islogged=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "bidno=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "approved=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "userid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "bidlimit=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "uname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   console.log('cookie gone dapat')

 }

function Navtop () {

    // const [categs, setCategs] = useState([]);
    // const [categs1, setCategs1] = useState([]);
    // const [categs2, setCategs2] = useState([]);
    
    const [modalShow, setModalShow] = React.useState(false);

    const [modalShowty, setModalShowty] = React.useState(false);

    const [thecookie, setTheCookie] = React.useState([]);

    
    const cunam = readCookie('uname'); 
    const cbidno = readCookie('bidno');
    const cuserid = readCookie('userid');
    const cemail = readCookie('email');
    const cbidlimit = readCookie('bidlimit');
    const islogged = readCookie('islogged');

    //console.log('islogged:', islogged)

    
    
    //var perCol = parseInt(categsmry.length / 2);
    //setCategs(categsmry);
    //const categs1 = (categsmry.slice(0,perCol));
    //const categs2 = (categsmry.slice(perCol,categsmry.length));

    // const openRegModal = () => {
    //   console.log("open modal");
    // }
    
    // display if correct siteid
    //console.log('site: ', sessionStorage.getItem('aucsite'))
    
    const dispatch = useDispatch();
    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/getcats?aucsiteid=${sessionStorage.getItem('aucsite')}`)
        .then(res => res.json())
        .then(
          (result) => {
            dispatch(categsmryUpdat(result));
          },
          (error) => {}
        )
    }, [window.location.href])

    const categsmry = useSelector(state => state.categsmry);
    //console.log(categsmry)
    //const isLogged = useSelector(state => state.isLogged);

    const searchkw = () => {
      //fetch(`https://bidlive.bid/rest/proja/api/remfwl?uid=${uid}&enum=${eqpn}`)
      //.then(res => res.json())
      //.then(
      //(result) => {
      //    //console.log('asdfasdfasdf',result)
      //    document.getElementById(wlrid).style.display = "none"
      //    // document.getElementById(theofa).style.display = "block"
      // },
      //(error) => {}
      //)
      
      alert(document.getElementById("thesearchkw").value);
      //console.log(allinsiteOrig);

    }

    

    


    return (

      <>     
      <Row style={{alignContent: 'center', backgroundColor: '#2e3957', height: '40px'}}>
        <Col sm={12} md={12}>
        <Container style={{paddingBottom: '0px', height: '25px'}}>
            <Row>
              <Col sm={4} md={4}   className="d-none d-md-block" >
                  <div style={{fontSize: '12px'}}>&nbsp;<FontAwesomeIcon icon={faPhone} />&nbsp;(047) 252 8000 / (02) 83967412</div>
              </Col>
              <Col sm={2} md={2}   className="d-none d-md-block">
                  <div style={{color: '#FFE66D', fontSize: '12px'}}></div>
              </Col>
              <Col sm={6} md={6} style={{textAlign: 'right'}}>

                  <div>
                
                  {islogged && <span style={{color: 'white', marginBottom: '20px'}}>
                    
                          <div style={{position:'absolute', paddingRight:'250px', paddingTop:'3px', width:'100%'}}>{cunam}</div>

                          &nbsp;&nbsp;&nbsp;
                          <Link to={`/mybidlive/${islogged}`}><Button size='sm' variant='primary'>My Bidlive&nbsp;&nbsp;<FontAwesomeIcon icon={faUser} /></Button>&nbsp;</Link>
                          
                          &nbsp;&nbsp;&nbsp;
                          <Link to={`/`}><Button size='sm' variant='outline-light' onClick={()=>deleteCookie()}>Log out&nbsp;<FontAwesomeIcon icon={faSignOutAlt} /></Button>&nbsp;</Link>

                      </span>
                  }
                  
                  
                  {!islogged && <span>
                      <Link to={`/login`}><Button size='sm' variant='outline-light'>Sign In&nbsp;<FontAwesomeIcon icon={faSignInAlt} /></Button></Link>&nbsp;
                      <Button size='sm' onClick={() => setModalShow(true)}>Register for Free</Button>
                    </span>
                  } 
                  
                  

                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onNext={() => setModalShowty(true)}
                  />
                  <ThankYouModal
                    show={modalShowty}
                    onHide={() => setModalShowty(false)}
                  />

                  </div>

              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      
     
      <Row style={{alignContent: 'center'}}>
        <Col sm={12} md={12}>
          
          <Container style={{height: '80px'}}>
            <Row><Col className="container clearfix">

            
            <Navbar collapseOnSelect expand="lg" bg="#ffffff" variant="light">

            <Navbar.Brand href="http://clubcamayan.com">
              <img src={bllogo}  className="d-none d-sm-block" style={{padding: '2px 10px', height: '75px', paddingBottom: '20px', paddingRight: '45px'}} alt='alt'/>
              <img src={bllogosmlr}  className="d-block d-sm-none d-md-none d-lg-none d-xl-none" alt='alt'/>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" style={{height:'40px', fontSize: '14px', paddingBottom:'10px', backgroundColor:'white', zIndex:'100'}}>
                <Nav className="mr-auto" style={{paddingLeft:'50px'}}>

                  {/* <Nav.Link href="#features" style={{paddingRight:'15px'}}>CATEGORIES</Nav.Link> */}

                  {/* <NavDropdown title="CATEGORIES" id="collasible-nav-dropdown" style={{paddingRight:'15px'}}>
                      {categsmry.map(tc => (
                          // <Link to={`/categ/${tc.catid}`} key={tc.catid}>
                            <NavDropdown.Item href={`/aucsite/${sessionStorage.getItem('aucsite')}/${tc.catid}`} style={{fontSize: '14px'}} key={tc.catid}>{tc.catdesc} ({tc.ilan})</NavDropdown.Item>
                          // </Link>
                          // <NavDropdown.Divider />
                      ))}
                      </NavDropdown> */}

                  {/* <NavDropdown title="MARKET PLACES" id="collasible-nav-dropdown" style={{paddingRight:'15px'}}>
                      <NavDropdown.Item href="/aucsite/TH1" style={{fontSize: '14px'}}>Furniture - Philippines</NavDropdown.Item>
                      <NavDropdown.Item href="/aucsite/ID1" style={{fontSize: '14px'}}>Machinery - Netherlands</NavDropdown.Item>
                      <NavDropdown.Item href="/aucsite/AP1" style={{fontSize: '14px'}}>Machinery and Industrial - Philippines</NavDropdown.Item>
                      
                  </NavDropdown> */}

                  <Nav.Link href="/aucsite/ID1" style={{paddingRight:'15px'}}>CLUB CAMAYAN DEALS</Nav.Link>
                  <Nav.Link href="/howtobuy" style={{paddingRight:'15px'}}>ABOUT US & HOW DEALS WORK</Nav.Link>
                  <Nav.Link href="/terms" style={{paddingRight:'15px', fontSize:'14px'}}>TERMS OF USE</Nav.Link>
                  <Nav.Link href="/contactus" style={{paddingRight:'15px', fontSize:'14px'}}>CONTACT US</Nav.Link>

                </Nav>
                <Nav>
                  
                  <Nav.Link eventKey={2}>
                    {/*}    
                    <InputGroup className="mb-3" size='sm' style={{paddingTop:'12px'}}>
                      <FormControl
                          id="thesearchkw"
                          type="text"
                          placeholder="Search items for sale..."
                          aria-label="search items for sale"
                          aria-describedby="basic-addon2"
                      />
                      <InputGroup.Append size='sm'>
                        <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faSearch} onClick={() => {searchkw()}} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    */}
                    
                  </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
            

            </Col></Row>
          </Container>

        </Col>
      </Row>

    </> 

    );
}

export default Navtop;