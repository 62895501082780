import React, { useState } from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CurrencyFormat from 'react-currency-format';
import CardDeck from 'react-bootstrap/CardDeck'
import CardGroup from 'react-bootstrap/CardDeck'
import Alert from 'react-bootstrap/Alert'

import { Link } from "react-router-dom";

import Countdown from 'react-countdown';

import Card from 'react-bootstrap/Card'
import { Button, CardColumns } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHeart as farFaHeart, faSmileWink, faImages} from '@fortawesome/free-regular-svg-icons'
import {faHeart as fasFaHeart, faMapMarkerAlt, faCommentsDollar, faComments } from '@fortawesome/free-solid-svg-icons'
import {faShoppingCart as fasSCart} from '@fortawesome/free-solid-svg-icons'



import flagthai from '../assets/img/flagThai.png';
import flagphil from '../assets/img/flagPhil.png';
import { date } from 'yup';

import ItemInquiryForm from './ModalItemInquiry.js'
import ItemInquiryTy from './ModalItemInquiryTy.js'

//import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'


function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


function ItemCard (payload) {

    
    // const ti = payload[0];
    // console.log('theitem:',payload.payload[0])
    // console.log('noimg:',payload.payload[1])

    
    const islogged = readCookie('islogged');
    const ti=payload.payload[0];
    const tinoimg=payload.payload[1];

    const [modalShow, setModalShow] = useState(false);
    const [modalShowty, setModalShowty] = useState(false);

    
    if (ti.forlisting){
        var idpath = 'itemdets'
    }else if(ti.forauction){
        var idpath = 'forsale'
    }else {
        var idpath = 'makeanoffer'
    }



    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          //return <Completionist />;
          fetch(`https://bidlive.bid/rest/proja/api/pifinished`)
          .then(res => res.json())
          .then(
              (result) => {
                //console.log('asdfasdfasdf',result)
                //document.getElementById(wlrid).style.display = "none"
                //document.getElementById(theofa).style.display = "block"
                console.log(result);
              },
              (error) => {
                console.log(error);
              }
          );
        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
    };

   
    const addtowatchlst = (eqpn,thefa,theofa) => {
        
        console.log(islogged);
        fetch(`https://bidlive.bid/rest/proja/api/add2wl?uid=${islogged}&enum=${eqpn}`)
        .then(res => res.json())
        .then(
        (result) => {
            //console.log('asdfasdfasdf',result)
            document.getElementById(thefa).style.display = "none"
            document.getElementById(theofa).style.display = "block"
    
        },
        (error) => {}
        )
        
        //console.log(curico)
        


    }

       
   

    return(
        
        
        
        <Card id={ti.equipnum} style={{border: '0px', padding: '0px', margin: '0px', height: '100%'}}>

            {/* {
                ti.imagelg !== "" 
                    ? <Card.Img variant="top" src={`https://bidlive.bid/photos/olaitems/${ti.imagelg}?${new Date().getTime()}`}/>
                    : <Card.Img variant="top" src={tinoimg} />
            } */}
            
            <Link to={`/${idpath}/${ti.equipnum}`} key={ti.equipnum}>
            
            {(ti.imgnames.length === 0)?
                <Card.Img variant="top" src={tinoimg} />
            :<>
                <Card.Img variant="top" src={`https://bidlive.bid/photos/olaitems/${(() => { 
                    let iarr = ti.imgnames.split(",");let ilan = iarr.length;let fi = iarr[0].replace(/'/g,"");
                    return fi;
                })()}?${new Date().getTime()}`}  />

                <span style={{position: 'absolute', top: '0%', left: '85%', color: 'white', fontSize: '18px', fontWeight: 'bold', textShadow: '1px 1px #000000'}}>
                {/* <FontAwesomeIcon icon={faImages} /> */}
                {(() => { 
                    let iarr = ti.imgnames.split(",");
                    let ilanpa = iarr.length > 1 ? `+${iarr.length-1}` : "";
                    return ilanpa;
                })()}
                </span>
            </> 
            }
            </Link>

        
        <Card.Body style={{backgroundColor: '#ffffff', textAlign: 'left', padding: '10px'}}>
            <Card.Title style={{color: 'black', textAlign: 'left', fontSize: '16px', fontWeight: 'bold', marginBottom: '0'}}>
            <Link to={`/${idpath}/${ti.equipnum}`} key={ti.equipnum} style={{color: 'black'}}>{ti.sdesc}</Link>
            </Card.Title>
            <Card.Text style={{marginTop: '0'}}>
                {ti.addinfo.substring(0,150)} {ti.addinfo.length>120?" --more...":""}

            
            </Card.Text>
        </Card.Body>

        <Card.Footer style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px', paddingBottom: '0px', backgroundColor: '#ffffff'}}>
            <Card.Text style={{marginTop: '0px'}}>
                
                
                {/*======================================================================================================================
                =========================================================================================================================*/}
                {(ti.forlisting || ti.foroffer)?<>
                    
                    {ti.itemloc}

                </>:<>
                    
                    Online&nbsp;
                    {(() => { 
                            const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            let current_datetime = new Date(ti.startdate)
                            let formatted_date = months[current_datetime.getMonth()]+" "+current_datetime.getDate() + ", " + current_datetime.getFullYear()
                                //console.log(formatted_date)
                            return formatted_date;
                    })()}
                
                </>}
                
                
                    <span id={`loc${ti.equipnum}`} style={{float: 'right', fontSize: '12px'}}>
                    {ti.countryid=='US'?<>
                        &nbsp;&nbsp;<a href="https://goo.gl/maps/XuTfUG92EVRCxMD27" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a>
                    </>:<>
                        &nbsp;&nbsp;<a href="https://maps.app.goo.gl/NUhMmQkP4yREznhz6" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a>
                    </>}    
                    </span>
                
                {(ti.forlisting)?<>{null}
                </>:<>
                    <span id={`heartr${ti.equipnum}`} style={{float: 'right', fontSize: '12px', cursor: 'pointer', display: 'block'}}>
                        <FontAwesomeIcon icon={farFaHeart} onClick={() => {
                            if (islogged>0){
                                addtowatchlst(`${ti.equipnum}`,`heartr${ti.equipnum}`,`hearts${ti.equipnum}`)
                            }else{
                                alert('Please sign-in to add to your watchlist.')
                            }
                        }} />
                    </span>

                    <span id={`hearts${ti.equipnum}`} style={{float: 'right', fontSize: '12px', color: 'red', display: 'none'}}>
                        <FontAwesomeIcon icon={fasFaHeart} />
                    </span>

                </>}
                <div style={{Float: 'left'}}>
                    {(ti.forlisting || ti.foroffer)?<>
                    </>:<>    
                        <span>{ti.HIBID?'Current Bid:':'Starts:'}</span>
                        <span style={{fontWeight: 'bold'}}><CurrencyFormat value={ti.bidstart} displayType={'text'} thousandSeparator={true} prefix={String.fromCharCode(ti.countryid!=="PH"?36:8369)} /></span>
                    </>}
                    
                </div>
                                
            </Card.Text>    
        </Card.Footer>
        <Card.Footer style={{backgroundColor: '#e9ecef', border: '0', textAlign: 'center'}}>
            {ti.forlisting?<>
                {/* <Button size='sm' onClick={() => setModalShow(true)} style={{margin: '0'}}><FontAwesomeIcon icon={fasSCart} />&nbsp;&nbsp;Buy Now</Button>&nbsp;&nbsp; */}

                <Link to={`/${idpath}/${ti.equipnum}`} key={ti.equipnum}><Button size='sm' style={{margin: '0'}}><FontAwesomeIcon icon={fasSCart} />&nbsp;&nbsp;Buy Now</Button></Link>&nbsp;&nbsp;

                <Button size='sm' onClick={() => {
                        if (islogged>0){
                            addtowatchlst(`${ti.equipnum}`,`heartr${ti.equipnum}`,`hearts${ti.equipnum}`)
                        }else{
                            alert('Please sign-in to add to your watchlist.')
                        }
                }} style={{margin: '0'}}><FontAwesomeIcon icon={farFaHeart} />&nbsp;&nbsp;Favorite</Button>

                <ItemInquiryForm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onNext={() => setModalShowty(true)}
                    theitem = {ti.equipnum}
                    theitemdesc = {ti.sdesc}
                    theitemsno = {ti.sno}
                />
                <ItemInquiryTy
                    show={modalShowty}
                    onHide={() => setModalShowty(false)}
                />
            </>:<></>}

            {ti.forauction?<>
                <span style={{color: 'gray', fontSize: '12px'}}><Countdown date={ti.enddate} renderer={cdrender} /> left to bid..</span>
                <Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}><Button variant="primary" style={{marginBottom: '0', paddingBottom: '0'}}>Details and BID</Button></Link>
            </>:<></>}

            {ti.foroffer?<>
                {/* <span style={{color: 'gray', fontSize: '12px'}}>High Offer: P1,234</span> */}
                <Link to={`/${idpath}/${ti.equipnum}`} key={ti.equipnum}><Button variant="primary" style={{marginBottom: '0', paddingBottom: '0'}}>Make an Offer or Buy Now</Button></Link>
            </>:<></>}

        </Card.Footer>
        </Card>

        
        
        
        


    )

}





export default ItemCard;

