import React from 'react';
import {Modal, Button} from 'react-bootstrap';


function WelcomeModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="thankyou"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          <Modal.Title id="thankyou">
            Club Camayan &nbsp;&nbsp;<span style={{fontSize: '14px'}}></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <div style={{alignItems: 'center'}}>
          <h6>
              Welcome to this unique and special platform for our member customers.
          </h6>
          <br />
          Its free to sign up to be a member, and you automatically get a P300 voucher to be applied to any purchase of merchandise items on this web site or in person at any of our gift shops.
        </div>
  
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default WelcomeModal;
  